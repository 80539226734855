import React from "react";
import "./AboutThree.css";
import about1 from "../../images/Janki.jpg";
import jyoti from "../../images/jyoti.jpg";
import prashant from "../../images/prashant.jpg"

const AboutThree = () => {
  return (
    <section className="section padding-top-0 padding-bottom-0" id="features">
      <div className="container">
        <div className="row">
          <div
            className="col-lg-5 col-md-12 col-sm-12 align-self-center"
            data-scroll-reveal="enter left move 30px over 0.6s after 0.4s"
          >
            <img
              src={about1}
              className="rounded-circle img-fluid d-block mx-auto"
              alt="App"
              style={{
                width: "70%",
                // height: "30%"
              }}
            />
          </div>
          <div className="col-lg-1"></div>
          <div className="col-lg-6 col-md-12 col-sm-12 align-self-center mobile-top-fix">
            <div className="left-heading">
              <h2 className="section-title">Janki Nayak - Founder</h2>
            </div>
            <div className="left-text">
              <p>
                She is a professional psychological counselor with expertise in
                Forensic psychology as well as multiple years of experience in
                dealing with Counselling of various sections of society across
                all age groups – worked with NGOs catering to children,
                especially students dealing with Motivation issues, Suicidal
                Tendencies, and physical abuse issues, Assistance regarding
                parenting, depression, anxiety, and relationship issues. Also,
                currently, she is working with the Indian Airforce as their
                psychological advisor cum counselor and is a certified yoga
                trainer as well, whose focus is helping people to cope with
                their issues in the holistic and gentlest possible manner.
              </p>
            </div>
          </div>
        </div>

        {/* <div className="row mt-5">
          <div
            className="col-lg-5 col-md-12 col-sm-12 align-self-center mt-6"
            data-scroll-reveal="enter left move 30px over 0.6s after 0.4s"
          >
            <img
              src={prashant}
              className="rounded-circle img-fluid d-block mx-auto"
              alt="App"
              style={{
                width: "70%",
              }}
            />
          </div>
          <div className="col-lg-1"></div>
          <div className="col-lg-6 col-md-12 col-sm-12 align-self-center mobile-top-fix">
            <div className="left-heading">
              <h2 className="section-title">Prashant Prajapati</h2>
            </div>
            <div className="left-text">
              <p>
              He is a counseling psychologist as well as a clinical hypnotherapist. 
              He has been working in this field for over 5 years. He developed a passion 
              for psychology at a young age. He is keen to help people facing mental health 
              issues. He is experienced in assessing and treating patients suffering from various 
              emotional, mental, and behavioral disorders. Highly skilled and experienced in 
              counseling patients suffering from cancer and terminal diseases. He is a member 
              of Academic Hypnosis. He believes that hypnosis is a great tool to treat many 
              types of mental as well as physical diseases, by using this scientific therapy 
              we can achieve amazing results in the area of psychological issues. He aims to 
              help people having stress, anxiety, psychosomatic illness, addiction, sleep 
              disorders and phobias. He wants to heal them positively and holistically.

              </p>
            </div>
          </div>
        </div> */}

        <div className="row">
          <div className="col-lg-12">
            <div className="hr"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutThree;
